<template>
    <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
          <div class="col-5">
          <h2 class="title2">Leave Types</h2>
          </div>
          <div class="col text-end">
              <a class="btn btn-outline-dark me-2" href="/export/fields/leavetypes">Export</a>
              <button class="btn btn-outline-dark" >import</button>
          </div>
      </div>
      <div v-if="this.$store.state.loader">
          <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
      </div>
      <div v-else class="row t-layout">
          <div v-if="$store.state.permittedActions.includes(141)" class="col-lg-6 mb-3">
              <div class="card">
            <div class="card-header">

            </div>
            <div class="card-body">
                <flash-message class="myCustomClass"></flash-message>
                <form  @submit.prevent="onSubmit($event)">
                    <div class="mb-3">
                        <label class="form-label">Leave name</label>
                        <input
                            class="form-control"
                            v-model="$v.form.leavetype.$model"
                            :validator="$v.form.leavetype"
                        />
                        <span
                            class="error "
                            v-if="$v.form.leavetype.$error == true"
                            >Please enter leave name
                        </span>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Credits</label>
                        <input
                            type="number"
                            class="form-control"
                            v-model="$v.form.credits.$model"
                            :validator="$v.form.credits"
                        />
                        <span
                            class="error "
                            v-if="$v.form.credits.$error == true"
                            >Please enter number of credits </span
                        >
                    </div>
                    <div class="mb-3">
                        <label class="form-label mb-2">Term</label>
                        <div>
                        <input
                            type="radio"
                            class="me-2"
                            id="monthly"
                            value="Monthly"
                            v-model="$v.form.term.$model"
                            :validator="$v.form.term"
                        />
                        <label class="me-3" for="monthly">Monthly</label>
                        <input
                            type="radio"
                            id="yearly"
                            class="me-2"
                            value="Yearly"
                            v-model="$v.form.term.$model"
                            :validator="$v.form.term"
                        />
                        <label for="yearly">Yearly</label>
                        <p>
                        <span
                            class="error "
                            v-if="$v.form.term.$error == true"
                            >Please select a term </span
                        >
                        </p>
                        </div>
                    </div>
                    <div class="text-right">
                        <button type="submit" class="btn btn-outline-primary" >Save</button>
                    </div>
                </form>
            </div>
          </div>
          </div>
          <div :class="$store.state.permittedActions.includes(141) ? 'col-lg-6 mb-3': 'col-lg-12 mb-3'">

            <div v-if="this.$store.state.loader">
                <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
            </div>
            <div v-else class="card">
            <div class="card-header">
            </div>
            <div class="card-body">

              <b-table
                class="table table-custom"
                :items="leaveTypes"
                :fields="fields"
                show-empty
                sticky-header
              >
              <template v-slot:cell(description)="data">
                  {{ data.item.leavetype }}
              </template>
              <template v-slot:cell(credit)="data">
                  {{ data.item.limit }}
              </template>
              <template v-slot:cell(term)="data">
                  {{ data.item.percalendar }}
              </template>
              <template v-slot:cell(action)="data">
                  <div class="d-inline-block">
                      <div class="d-flex justify-content-md-around">
                          <a v-if="$store.state.permittedActions.includes(142) " class="button button1" title="Edit" @click.prevent="deleteLeaveType(data.item.id)"><i class="icon-bin"></i></a>
                      </div>
                  </div>
              </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from 'axios';

export default {
   data(){
       return {
           isModalVisible: false,
           form: {
               leavetype: null,
               credits: null,
               term: null
           },
           fields:[
               {
                   key: 'description',
                   tdAttr: { 'custom-label'  : "Description:"}
               },
               {
                key: 'credit',
                   tdAttr: { 'custom-label'  : "Credit:"}
               },
                {
                key: 'term',
                    tdAttr: { 'custom-label'  : "Term:"}
               },
               {
                   key: 'action',
                   tdAttr: { 'custom-label'  : "Action:"}
               }
           ]
       }
   },
   validations: {
       form: {
           leavetype: {
               required
           },
           credits: {
               required
           },
           term: {
               required
           }
       }
   },
   computed:{
       leaveTypes(){
           return this.$store.state.leaveTypeData;
       }
   },
   created(){
       this.$store.dispatch('switchLoader', true);
       this.$store.dispatch('loadLeaveTypeData');
   },
  
   methods:{
       
       onSubmit(event){
            this.$v.$touch();
            if (this.$v.$invalid) return;
            axios
                .post("/fields/leavetype/add", this.form)
                .catch(({ response }) => {
                this.flashMessage.error({
                    message: "Something went wrong!",
                    time: 3000,
                });
                })
                .then(({ data }) => {
                if (data.success) {
                    this.form.department = null;
                    this.flashMessage.success({
                    message: data.success,
                    time: 3000,
                    });
                    this.$store.dispatch('loadLeaveTypeData');
                } else {
                    this.flashMessage.error({
                    message: data.error,
                    time: 3000,
                    });
                }
                });
       },
       deleteLeaveType(id){
           if(confirm('Are you sure?')){
                axios.get("/fields/leavetype/delete/"+id)
                    .catch(({ response }) => {
                    this.flashMessage.error({
                        message: "Something went wrong!",
                        time: 3000,
                    });
                    })
                    .then(({ data }) => {
                    if (data.success) {
                        this.form.department = null;
                        this.flashMessage.success({
                        message: data.success,
                        time: 3000,
                        });
                        this.$store.dispatch('loadLeaveTypeData');
                    } else {
                        this.flashMessage.error({
                        message: data.error,
                        time: 3000,
                        });
                    }
                    });
            }
       }
   }
}
</script>
